<template>
    <div class="performance-container">
        <div class="dashboard-title">
            {{ title }}
            <div class="info-icon" :tooltip="tips.title" tooltip-touch />
        </div>
        <div v-if="isLoading" class="process-loading-icon" />
        <DashboardErrorMessage
            v-if="isError && !isLoading"
            imgHeight="70px"
            :functionName="
                'self' ? 'getPersonalPerformanceAPI' : 'getOrgPerformanceAPI'
            "
            @reacquire="
                mode === 'self' ? getPersonalPerformance() : getOrgPerformance()
            "
        />
        <TagMenu
            :options="dataOptions"
            :isDashboard="true"
            :class="{ invisible: isError }"
            v-model="dataTypeValue"
        />

        <div class="chart-area" :class="{ invisible: isLoading || isError }">
            <DoughnutChart
                :datasets="chartData"
                totalUnit="FYC"
                :totalValueSlot="performanceData?.total ?? null"
            />
            <div class="label-container">
                <div class="life">
                    <template
                        v-for="(item, index) in dataset.life"
                        :key="index"
                    >
                        <div class="item">
                            <div>
                                <div class="item-label">
                                    <span
                                        class="color-block"
                                        :style="{ background: item.color }"
                                    />
                                    {{ item.label }}
                                </div>

                                <div
                                    class="fyc"
                                    v-text="$numberWithComma(item?.value)"
                                />
                            </div>
                            <div
                                class="circle-arrow-right-btn"
                                @click="
                                    rqueryOnPerformancePage('life', item.key)
                                "
                            />
                        </div>
                    </template>
                </div>
                <div class="property">
                    <template
                        v-for="(item, index) in dataset.property"
                        :key="index"
                    >
                        <div class="item" v-if="!item.isLife">
                            <div>
                                <div class="item-label">
                                    <span
                                        class="color-block"
                                        :style="{ background: item.color }"
                                    />
                                    {{ item.label }}
                                </div>

                                <div
                                    class="fyc"
                                    v-text="$numberWithComma(item?.value)"
                                />
                            </div>
                            <div
                                class="circle-arrow-right-btn"
                                @click="
                                    rqueryOnPerformancePage(
                                        'property',
                                        item.key
                                    )
                                "
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import TagMenu from '@/components/TagMenu.vue'
import DoughnutChart from '@/components/DoughnutChart.vue'
import DashboardErrorMessage from '@/containers/home/DashboardErrorMessage.vue'
import { getDashboardPersonalPerformanceAPI } from '@/assets/javascripts/api.js'
import { getDashboardOrgPerformanceAPI } from '@/assets/javascripts/api.js'

export default {
    name: 'DashboardPerformanceChart',
    components: {
        TagMenu,
        DoughnutChart,
        DashboardErrorMessage
    },
    props: {
        mode: {
            type: String,
            default: 'self' //org
        }
    },
    methods: {
        getPersonalPerformance: async function () {
            if (this.mode !== 'self') return
            this.isLoading = true
            this.isError = false
            try {
                const res = await getDashboardPersonalPerformanceAPI()
                this.$store.commit('set', {
                    dashboardPersonalPerformance: res.data
                })
            } catch {
                this.$store.commit('set', {
                    dashboardPersonalPerformance: null
                })
                this.isError = true
            } finally {
                this.isLoading = false
            }
        },
        getOrgPerformance: async function () {
            if (this.mode !== 'org') return
            this.isLoading = true
            this.isError = false
            try {
                const res = await getDashboardOrgPerformanceAPI()
                this.$store.commit('set', {
                    dashboardOrgPerformance: res.data
                })
            } catch {
                this.$store.commit('set', {
                    dashboardOrgPerformance: null
                })
                this.isError = true
            } finally {
                this.isLoading = false
            }
        },
        rqueryOnPerformancePage: function (jobType, key) {
            this.$setGaEvent(
                `filterPerformance_${jobType}_${key}`,
                'cross-page-filter'
            )
            const query = this.performanceData[this.dataTypeValue].payload[
                jobType
            ]

            this.$crossPageFilter('Performance', query[key], {}, true)
        }
    },
    computed: {
        isSectionShow: function () {
            return !this.$store.state.isSectionHidden
        },
        isSelf: function () {
            return this.mode === 'self'
        },
        title: function () {
            return this.isSelf ? '個人業績' : '組織業績'
        },
        dataOptions: function () {
            return [
                { label: '本月受理業績', value: 'submitOfMonth' },
                { label: '年度受理業績', value: 'submitOfYear' },
                { label: '年度核實業績', value: 'commitOfYear' }
            ]
        },
        labelConfig: function () {
            return {
                life: {
                    general: { label: '一般', color: '#D888F4' },
                    fourWin: { label: '四贏', color: '#00ADEE' },
                    special: { label: '特定', color: '#6B8BE4' }
                },
                property: {
                    general: {
                        label: '產團旅險',
                        color: '#FFD54F'
                    }
                }
            }
        },
        performanceData: function () {
            return this.mode === 'self'
                ? this.$store.state?.dashboardPersonalPerformance
                : this.$store.state?.dashboardOrgPerformance
        },
        dataset: function () {
            const lifeData = this.performanceData
                ? _.cloneDeep(
                      this.performanceData[this.dataTypeValue]?.detail?.life ??
                          {}
                  )
                : {}
            const propertyData = this.performanceData
                ? _.cloneDeep(
                      this.performanceData[this.dataTypeValue]?.detail
                          ?.property ?? {}
                  )
                : {}
            const lifeList = _.cloneDeep(this.labelConfig.life)
            const propertyList = _.cloneDeep(this.labelConfig.property)

            Object.keys(lifeList).forEach((key) => {
                lifeList[key].value = lifeData[key] ?? 0
                lifeList[key].key = key
            })
            Object.keys(propertyList).forEach((key) => {
                propertyList[key].value = propertyData[key] ?? 0
                propertyList[key].key = key
            })
            return { life: lifeList, property: propertyList }
        },
        chartData: function () {
            return [
                ...Object.values(this.dataset.life),
                ...Object.values(this.dataset.property)
            ]
        },
        personalStatues: function () {
            return this.dashboardStatus.personalPerformance
        },
        orgStatues: function () {
            return this.dashboardStatus.orgPerformance
        }
    },
    watch: {
        isSectionShow: function (newVal) {
            //解決section CSS動畫的延遲造成跑版
            if (newVal) {
                this.hasSectionClass = this.isSectionShow
            } else {
                setTimeout(() => {
                    this.hasSectionClass = this.isSectionShow
                }, 400)
            }
        },
        performanceData: {
            handler(newVal) {
                if (newVal === null) {
                    this.isError = true
                } else {
                    this.isError = false
                }
            },
            deep: true,
            immediate: true
        },
        dataTypeValue: function (newVal) {
            this.$setGaEvent(`${newVal}Select`, 'toggle-menu')
        }
    },
    data() {
        return {
            hasSectionClass: true,
            dataTypeValue: 'submitOfMonth',
            isLoading: false,
            isError: false,
            tips: { title: '顯示業績為原始業績' }
        }
    },
    mounted() {
        //個人業績圖表
        if (
            !this.$store.state.dashboardOrgPerformance ||
            (this.$store.state.dashboardPersonalPerformance &&
                Object.keys(this.$store.state?.dashboardPersonalPerformance)
                    .length === 0) ||
            this.$shouldUpdateHomeApi()
        ) {
            this.getPersonalPerformance()
        }

        //組織業績圖表
        if (
            !this.$store.state.dashboardOrgPerformance ||
            (this.$store.state.dashboardOrgPerformance &&
                Object.keys(this.$store.state?.dashboardOrgPerformance)
                    .length === 0) ||
            this.$shouldUpdateHomeApi()
        ) {
            this.getOrgPerformance()
        }
    }
}
</script>
<style lang="scss" scoped>
.performance-container {
    position: relative;
    .info-icon {
        margin-left: 5px;
        width: 14px;
        height: 14px;
    }
}

:deep(.scroll-wrapper) {
    &.invisible {
        visibility: hidden;
    }
    .dashboard {
        .menu-item {
            &:not(:last-child) {
                margin-right: 35px;
            }
            @media screen and (max-width: 420px) {
                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }
    }
}

.chart-area {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    &.invisible {
        visibility: hidden;
    }
    @media (min-width: 576px) and (max-width: 1017px) {
        margin-top: 35px;
    }
    @media screen and (max-width: 576px) {
        flex-direction: column;
        margin-top: 20px;
        .label-container {
            margin-top: 20px;
        }
    }
}

.item {
    display: flex;
    align-items: center;
    > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .item-label {
            display: flex;
            justify-content: space-between;
            width: 73px;
            font-size: 12px;
            line-height: 17px;
            color: $sixth-black;
            margin: 0px 0px 0px auto;
            cursor: default;
            .color-block {
                display: block;
                width: 15px;
                height: 15px;
                border-radius: 3px;
            }
        }
        .fyc {
            font-family: $number-en;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: $sixth-black;
            margin-top: 5px;
            width: 100px;
            text-align: right;
        }
    }
    .circle-arrow-right-btn {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-left: 10px;
        float: right;
    }
}

@mixin border() {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 5px;
    padding: 2px;
    background: linear-gradient(
        to right,
        $eighth-grey-disabled-light 0px,
        $eighth-grey-disabled-light 10px,
        rgba(255, 255, 255, 0) 10px,
        rgba(255, 255, 255, 0) 100%
    );
    -webkit-mask: linear-gradient($primary-white 0 0) content-box,
        linear-gradient($primary-white 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}

@mixin type-label() {
    position: absolute;
    color: $placeholder-black;
    font-size: 14px;
    height: 100%;
    left: -35px;
    display: flex;
    align-items: center;
}

.life,
.property {
    position: relative;
    display: flex;
    flex-direction: column;
    // gap: 15px;
    padding-left: 10px;
    margin-left: 43px;
    height: fit-content;
    align-items: center;
    background: $primary-white;
    border: 2px solid transparent;
    border-radius: 5px;
    .item {
        &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }
    @media (min-width: 375px) and (max-width: 576px) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 33px;
        // gap: 8px;
        padding-left: 5px;
        .item {
            &:not(:last-child) {
                margin-right: 8px;
                margin-bottom: 8px;
            }
        }
    }
    @media screen and (max-width: 374px) {
        margin: 0 auto;
        .item {
            &:not(:last-child) {
                margin-right: 0px;
                margin-bottom: 8px;
            }
        }
    }
}

.life {
    margin-bottom: 15px;
    &::before {
        content: '壽險';
        @include type-label;
    }
    &::after {
        @include border();
    }
}
.property {
    &::before {
        content: '產險';
        @include type-label;
        display: none;
    }
    &::after {
        @include border();
        display: none;
    }
    @media screen and (max-width: 576px) {
        &::before {
            display: flex;
        }
        &::after {
            display: inline;
        }
    }
}

.process-loading-icon {
    position: absolute;
    top: 55%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 30px;
    height: 30px;
}
:deep(.error-container) {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 0 auto;
}
</style>
